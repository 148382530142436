<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/banner/lists">Quản lý Banner </a>
      </li>

      <li class="breadcrumb-item">Thêm mới Banner</li>
    </ol>

    <form method="post" @submit="mySubmit" id="sendform">
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >

            </a>
          </li>
          
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Tên<span class="text-danger">*</span></label
                          >
                          <input type="text" name="name" class="form-control" />
                          <p class="text-danger">{{ error.name }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">

                          <label for="picturefile"
                            >Hình ảnh đại diện<span class="text-danger"
                              >* (Trên cùng: 395 x 165 pixels, Bên Phải: 236 x 315 pixels)</span
                            ></label
                          >
                          <input
                            type="file"
                            accept="image/*"
                            name="picturefile"
                            class="form-control main_picture"
                            @change="onPicturefile"
                          />

                          <div class="view_picture_one" v-if="img_one != ''">
                            <img :src="img_one" style="max-width: 400px" />
                          </div>
                          <p class="text-danger">{{ error.picturefile }}</p>
                        </div>
                      </div>


                       <div class="col-sm-10">
                        <div class="form-group row">
                          <div class="col-sm-3">
                            <label for="name"> Vị trí </label>
                          </div>
                          <div class="col-sm-9">
                            <div class="radio">
                              <label>
                                <input
                                  class=""
                                  name="flagIndex"
                                  type="radio"
                                  value="1"
                                  v-model="flagIndex"
                                />
                                Trên cùng 
                              </label>
                            </div>
                            <div class="radio">
                              <label>
                                <input
                                  class=""
                                  name="flagIndex"
                                  type="radio"
                                  value="2"
                                  v-model="flagIndex"
                                />
                                Bên phải 
                              </label>
                            </div>

                             <div class="radio">
                              <label>
                                <input
                                  class=""
                                  name="flagIndex"
                                  type="radio"
                                  value="3"
                                  v-model="flagIndex"
                                />
                                Bên Trái 
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="description">Đường dẫn </label>
                          <input
                            type="text"
                            name="description"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="description">STT </label>
                          <input
                            type="number"
                            name="flagContent"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fa fa-save"></i> Save
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      flagIndex:'1',
      img_one: "",
      is_load: false,
      error: {
        name: "",
        picturefile: "",
        name_EN: "",
      },
    };
  },
  mounted() {},

  methods: {
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function (e) {
      e.preventDefault();
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }
        if (pair[0] == "picturefile") {
          if (pair[1].name == "") {
            this.error.picturefile = "Vui lòng nhập hình.";
          } else {
            this.error.picturefile = "";
          }
        }
       
      }
      if (
        this.error.name == "" &&
        this.error.picturefile == "" &&
        this.error.name_EN == ""
      ) {
        this.is_load = true;
        axios
          .post(window.my_api + "api/banner/create-or-update-banner", formData,
              {
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
            
              alert("Thêm thành công.");
               this.$router.go(this.$router.currentRoute);
              document.getElementById("sendform").reset();
              this.img_one = "";
            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
            }
            this.is_load = false;
          });
      } else {
        

        alert("Vui lòng nhập đầy đủ dữ liệu.");
      }
    },
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>